<template>
  <div class="course-play">
    <div class="banner">
      <div class="course-content">
        <div class="course-title">
          <span>{{ section.name }}</span>
          <div class="ope-box">
            <!-- <span class="ope phone-show">手机观看</span>
            <span class="ope copy">复制</span> -->
            <span
              class="ope collect"
              :class="isCollect == 1 ? 'active' : ''"
              @click="heartBtn"
              >{{ isCollect == 1 ? "已收藏" : "收藏" }}</span
            >
          </div>
        </div>
        <div class="play-content">
          <div class="video-box">
            <div id="J_prismPlayer"></div>
            <!-- <div class="progress" v-if="websiteData.is_course_drag == 0">
              <p class="lines" :style="{ width: section.progress + '%' }"></p>
            </div> -->
            <div class="marquee" v-if="marqueeState">
              <marquee scrollamount="5">{{marqueeText}}</marquee>
            </div>
          </div>
          <div class="play-list">
            <div class="play-title" v-if="chapters">
              {{ chapters.course.name }}
            </div>
            <ul class="course-list">
              <li
                class="course-info"
                v-for="item in chapters.chapters"
                :key="item.id"
              >
                <div class="course-name">{{ item.name }}</div>
                <ul class="course-info-list">
                  <li
                    class="course-section"
                    :class="selectId == section.id ? 'active' : ''"
                    v-for="section in item.sections"
                    :key="section.id"
                    @click="getPlayInfo(section, true)"
                  >
                    <span>{{ section.name }}</span
                    ><span class="state">{{
                      section.progress == 0
                        ? "未开始"
                        : section.progress == 100
                        ? "已完成"
                        : section.progress + "%"
                    }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="fileList" style="width: 100%">
        <el-table-column prop="name" label="课件名称"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="downloadFile(scope.row.url, scope.row.name)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="evaluate-content">
      <div class="evaluate">
        <template v-if="websiteData.is_comment == 1">
          <Star :type="'ope'" @returnNum="getScore" />
          <textarea
            class="evaluate-text"
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="textarea"
          ></textarea>
          <div class="evaluate-ope">
            <label class="anonymity"
              ><input type="checkbox" v-model="anonymous" /> 是否匿名</label
            >
            <button class="submit-btn" @click="submitBtn">提交评价</button>
          </div>
        </template>
        <div class="evaluate-h">
          课程评价
          <span v-if="evaluation && evaluation.data.length > 0"
            >({{ evaluation.data.length }})</span
          >
        </div>
        <ul class="evaluate-list">
          <li class="evaluate-info" v-for="item in evaluation.data" :key="item.id">
            <img
              class="user-img"
              :src="
                item.avatar || require('@/assets/head-default-personal.png')
              "
            />
            <div class="evaluate-content">
              <div class="evaluate-title">
                {{ item.name }}
                <span class="date">发布于{{ item.created_at }}</span>
              </div>
              <div class="grade">
                <Star :type="'show'" :scoreNum="item.score" />
                <span class="grade-statistic">{{ item.score }}分</span>
              </div>
              <div class="evaluate-text">{{ item.content }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Star from "@/components/pc/star";
import { download } from '@/utils/download.js'
import { mapMutations } from "vuex";
export default {
  name: "CoursePlay",
  data() {
    return {
      selectId: "",
      tabIndex: 1,
      courseId: "", //课程id
      playId: "", //播放节id
      chapters: "", //章节
      evaluation: "", //评价详情
      score: "", //评分
      anonymous: false, //是否匿名
      textarea: "", //评价内容
      section: "", //当前章节
      timer: "", //计时器
      isCollect: 0, //是否收藏
      autoPlayNextTip: "秒后自动播放下一节", //自动播放下一节提示语
      autoPlayNextTime: 5, //自动播放下一节时间 秒
      hostLink: "",
      websiteData: "",
      linkUrlCont:'',
      timesNum: 5000,
      timesInterval: "",
      unique_id:'',//随机数
      flowTimer:'',//流量计时器
      marqueeState:false,//视频水印是否开始
      marqueeText:'',//水印内容
      fileList: [],
    };
  },
  components: {
    Star,
  },
  mounted() {
    // 获取随机数
    this.unique_id=String(new Date().getTime())+String(Math.round(Math.random()*999999));
    
    this.hostLink =
      window.location.host == "localhost:8081"
        ? "kskweb.kskstudy.com"
        : window.location.host;
    this.websiteData = JSON.parse(localStorage.getItem("websiteState"));
    this.linkUrlCont = JSON.parse(localStorage.getItem("siteInfo"));
    if (this.$route.params.id.indexOf("&") != -1) {
      this.courseId = this.$route.params.id.split("&")[0];
      this.playId = this.$route.params.id.split("&")[1];
      this.selectId = this.playId;
    } else {
      this.courseId = this.$route.params.id;
    }
    this.getChapterSection(); //获取课程章节
    this.getEvaluation(); //获取评价
    this.getCourseInfo(); //获取课程详情
    this.getFiles();  // 获取课程附件
    this.timesNum =this.websiteData.lesson_prompt_time_limit? this.websiteData.lesson_prompt_time_limit*1000*60:'0'
    this.marqueeText = JSON.parse(localStorage.getItem('userInfo')).name
  },
  methods: {
    ...mapMutations(["ShowTipModal", "CloseTipModal"]), //tip弹窗
    // getNewData(){
    //   this.times = 5000
    //    setTimeout(()=>{
    //       this.times -=1000
    //       if(this.timer==0){
    //         this.getNewData()
    //       }
    //   },this.times)
    // },
    // 获取课程附件列表
    getFiles() {
      this.$axios.post(`/v1/course/attachment`, { course_id: this.courseId },{ useLog: true }).then((res) => {
        if (res.code == 0) {
          this.fileList = res.data.list;
          console.log(this.fileList);
        }
      })
    },
    downloadFile(url, name) {
      console.log(url, name);
      download(url, name)
    },
    //获取课程章节
    getChapterSection() {
      this.$axios
        .post(
          `/v1/course/play/chapterSection`,
          { course_id: this.courseId, host: this.hostLink },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            this.chapters = res.data;
            let chooseItem = {};
            // debugger
            if (this.playId) {
              for (var i = 0; i < res.data.chapters.length; i++) {
                for (let j = 0; j < res.data.chapters[i].sections.length; j++) {
                  if (res.data.chapters[i].sections[j].id == this.playId) {
                    chooseItem = res.data.chapters[i].sections[j];
                    this.getPlayInfo(chooseItem);
                  }
                }
              }
            } else {
              this.getPlayInfo(res.data.chapters[0].sections[0]); //获取播放
            }
          } else {
            this.$router.go(-1);
          }
        });
    },
    //章节播放
    getPlayInfo(section, play) {
      let _this = this;
      this.selectId = section.id;
      this.section = section;

      this.$axios
        .post(
          `/v1/course/play/resource`,
          {
            course_id: this.courseId,
            section_id: section.id,
            host: this.hostLink,
          },
          { useLog: true }
        )
        .then((res) => {
          let playInfo = res.data;

          if (this.player) {
            this.player.dispose();
          }

          this.player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              source: playInfo.info.original_link, //视频地址
              autoplay: false,
              showBarTime: 1000,
              skinLayout: [
                { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "tlabs", x: 0, y: 0 }, //隐藏进度条
                    { name: "playButton", align: "tl", x: 15, y: 13 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 6 },
                    { name: "fullScreenButton", align: "tr", x: 20, y: 12.5 },
                    { name: "volume", align: "tr", x: 20, y: 12.5 },
                  ],
                },
              ],
            },
            function (player) {
              player.on('ready', () => {
                //自动播放
                if(play){
                  _this.player.play();
                }
              })

              //开始播放以后监听
              player.on("play", () => {
                if(_this.linkUrlCont.is_open_video_watermark==1){
                  // 开启水印
                  _this.marqueeState = true
                }
                 _this.getVideoFlow(); //记录流量
                if (player.getStatus() != "pause") {
                  _this.$axios
                    .post(`/v1/px/course/courseRecord`, {
                      course_id: _this.courseId,
                      type: 1,
                    })
                    .then(() => {}); //记录播放
                    
                  //获取进度
                  let jindu = section.progress;
                  if (jindu > 0 && jindu < 100) {
                    // jindu = (jindu / 100) * player.getDuration();
                    player.seek(res.data.finish_duration); //跳转到某个已加载的时刻进行播放，时间单位：秒。
                    //禁止推拽进度条
                    if (_this.websiteData.is_course_drag == 0) {
                      player.seek = (time) => {};
                    }
                  }
                }
               if( _this.timesNum >0){
                 _this.timesInterval = setInterval(() => {
                  // _this.timesNum = _this.timesNum - 1000;
                  // if (_this.timesNum == 0) {
                    _this.timesNum = this.websiteData.lesson_prompt_time_limit*1000*60
                    _this.player.pause();
                    clearInterval(_this.timesInterval);
                    clearInterval(_this.timer);
                    clearInterval(_this.flowTimer);
                    _this.ShowTipModal({
                      text: "是否继续观看？", //提示弹窗内容
                      showBtns: true,
                      subBtnText: "继续",
                      confirmFun: function () {
                        _this.CloseTipModal();
                       _this.player.play();

                      },
                    });
                  // }
                }, _this.websiteData.lesson_prompt_time_limit*1000*60);
               }
                _this.recordLength(); //记录时长
              });

              //暂停播放
              player.on("pause", function () {
                if (_this.timer) {
                  clearInterval(_this.timesInterval);
                  clearInterval(_this.timer);
                
                  
                }
                  clearInterval(_this.flowTimer);
              });

              //播放结束
              player.on("ended", function () {
                if (_this.timer) {
                  clearInterval(_this.timer);
                  clearInterval(_this.timesInterval);
                
                }
                  clearInterval(_this.flowTimer);
                _this.recordLength(); //记录时长
                // _this.getVideoFlow(); //记录流量
                _this.playNext(); //自动播放下一节
              });
            }
          );
        });
    },
    getMakeBox() {
      this.timesInterval = setInterval(() => {
        this.timesNum = this.timesNum - 1000;
        if (this.timesNum == 0) {
          this.timesNum = 5000;
          clearInterval(this.timesInterval);
          // player.on("pause", function () {
          //   if (this.timer) {
          //     clearInterval(this.timer);
          //   }
          // });
        }
      }, 1000);
    },
    // 记录流量
    getVideoFlow(){
      let _this = this
      clearInterval(this.flowTimer)
      this.flowTimer = setInterval(()=>{
        this.$axios.post(`/v1/course/statistics/statisticsVideoFlow`,{
          section_id: _this.section.id,
          unique_id:_this.unique_id
        }).then(res=>{
          if(res.code==0){
            for (let i = 0; i < _this.chapters.chapters.length; i++) {
                if (_this.chapters.chapters[i].id == _this.section.chapter_id) {
                  for (
                    let o = 0;
                    o < _this.chapters.chapters[i].sections.length;
                    o++
                  ) {
                    if (
                      _this.chapters.chapters[i].sections[o].id ==
                      _this.section.id
                    ) {
                      _this.chapters.chapters[i].sections[o].progress =
                        res.data.progress;
                    }
                  }
                }
              }
          }
        })
      },10000)
    },
    //记录时长
    recordLength() {
      let _this = this;
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        _this.$axios
          .post(
            `/v1/course/play/recordStudyDuration`,
            {
              course_id: _this.courseId,
              section_id: _this.section.id,
              play_duration: parseInt(_this.player.getCurrentTime()),
              total_duration: parseInt(_this.player.getDuration()),
              host: this.hostLink,
            },
            { useLog: true }
          )
          .then((res) => {
            if (res.code == 0) {
              for (let i = 0; i < _this.chapters.chapters.length; i++) {
                if (_this.chapters.chapters[i].id == _this.section.chapter_id) {
                  for (
                    let o = 0;
                    o < _this.chapters.chapters[i].sections.length;
                    o++
                  ) {
                    if (
                      _this.chapters.chapters[i].sections[o].id ==
                      _this.section.id
                    ) {
                      _this.chapters.chapters[i].sections[o].progress =
                        res.data.progress;
                    }
                  }
                }
              }
            }
          });
      }, 10000);
    },

    //播放下一节逻辑
    playNext() {
      let chapterList = this.chapters.chapters;
      let section = this.section;
      let playSection = null;
      let autoPlayNextTime = this.autoPlayNextTime; //倒计时时间
      let autoPlayTime = null; //倒计时

      for (let i = 0; i < chapterList.length; i++) {
        if (chapterList[i].id == section.chapter_id) {
          let sections = chapterList[i].sections;

          for (let o = 0; 0 < sections.length; o++) {
            if (sections[o].id == section.id) {
              if (o == sections.length - 1 && i == chapterList.length - 1) {
                console.log("本课程已播放完");
              } else {
                let _this = this;

                this.ShowTipModal({
                  text: _this.autoPlayNextTime + _this.autoPlayNextTip, //提示弹窗内容
                  showBtns: true,
                  confirmBtnText: "直接播放下一节",
                  cancelBtnText: "重新播放本节",
                  confirmFun: function () {
                    if (o < sections.length - 1) {
                      _this.selectId = sections[o + 1].id;
                      playSection = sections[o + 1];
                    } else {
                      if (i < chapterList.length - 1) {
                        _this.selectId = chapterList[i + 1].sections[0].id;
                        playSection = chapterList[i + 1].sections[0];
                      }
                    }

                    clearInterval(autoPlayTime);
                    _this.CloseTipModal();
                    _this.getPlayInfo(playSection, true);
                  },
                  cancelFun: function () {
                    clearInterval(autoPlayTime);
                    _this.CloseTipModal();
                    _this.player.play();
                  },
                });

                //自动播放倒计时
                autoPlayTime = setInterval(() => {
                  autoPlayNextTime--;
                  _this.$store.state.tipModal.text =
                    autoPlayNextTime + _this.autoPlayNextTip;

                  if (autoPlayNextTime == 0) {
                    clearInterval(autoPlayTime);
                    _this.$store.state.tipModal.confirmFun();
                    _this.CloseTipModal();
                  }
                }, 1000);
              }
            }
          }
        }
      }
    },
    //获取评价
    getEvaluation() {
      this.$axios
        .post(`/v1/course/detail/evaluation`, {
          id: this.courseId,
          page: 1,
          pre_page: 10,
        })
        .then((res) => {
          this.evaluation = res.data;
        });
    },
    //获取子组件分数
    getScore(data) {
      this.score = data;
    },
    //提交评价
    submitBtn() {
      if (this.score == 0) {
        this.ShowTipModal({
          text: "请先打分", //提示弹窗内容
        });

        return;
      }

      if (this.textarea == "") {
        this.ShowTipModal({
          text: "请填写评价内容", //提示弹窗内容
        });

        return;
      }

      this.$axios
        .post(
          `/v1/course/play/evaluation`,
          {
            course_id: this.courseId,
            is_anonymous: this.anonymous ? 1 : 0,
            score: this.score,
            content: this.textarea,
          },
          { useLog: true }
        )
        .then((res) => {
          this.$axios
            .post(`/v1/px/course/courseRecord`, {
              course_id: this.courseId,
              type: 2,
            })
            .then(() => {}); //记录评价
          if (res.code == 0) {
            let _this = this;
            this.ShowTipModal({
              text: "评论成功", //提示弹窗内容
            });

            setTimeout(function () {
              _this.score = 0;
              _this.textarea = "";
              _this.getEvaluation();
            }, 2000);
          } else {
            this.ShowTipModal({
              text: "评论失败，请稍后重新评论", //提示弹窗内容
            });
          }
        });
    },
    //收藏按钮
    heartBtn() {
      this.$axios
        .post(
          `/v1/course/detail/collect`,
          { id: this.courseId },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            this.isCollect = this.isCollect == 0 ? 1 : 0;
            if (this.isCollect == 1) {
              this.$axios
                .post(`/v1/px/course/courseRecord`, {
                  course_id: this.courseId,
                  type: 3,
                })
                .then(() => {}); //记录收藏
            }
          }
        });
    },
    //获取课程详情
    getCourseInfo() {
      let data = {
        // id: this.$route.params.id,
        id: this.courseId,
        user_id: JSON.parse(localStorage.getItem("user")).id,
      };

      this.$axios.post(`/v1/course/detail`, data).then((res) => {
        this.isCollect = res.data.is_collect;
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.player) {
      this.player = null;
    }
  },
};
</script>
<style scoped lang="scss">
.prism-progress {
  pointer-events: none;
}

.course-play {
  background-color: #f7f7f7;
}
.banner {
  width: 100%;
  padding: 60px 0;
  background: url(~@/assets/top-bg.png) no-repeat center center;
  background-size: auto 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.anonymity {
  cursor: pointer;
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
  input {
    margin-right: 6px;
  }
}
.table {
  width: 1200px; 
  margin: 0 auto;
  margin-top: 20px;
}
.course-content {
  width: 1200px;
  background: #101832;

  overflow: hidden;
  .course-title {
    padding: 13px 24px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    .ope-box {
      .ope {
        padding-left: 24px;
        font-size: 14px;
        color: #fff;
        margin-left: 45px;
        cursor: pointer;
        &.phone-show {
          background: url(~@/assets/phone-grey-ico.png) no-repeat left center;
          background-size: 16px auto;
        }
        &.copy {
          background: url(~@/assets/copy-gery-ico.png) no-repeat left center;
          background-size: 18px auto;
        }
        &.collect {
          background: url(~@/assets/star-air-ico.png) no-repeat left center;
          background-size: 20px auto;
          &.active {
            background: url(~@/assets/star-white-ico.png) no-repeat left center;
            background-size: 20px auto;
          }
        }
      }
    }
  }
  .play-content {
    overflow: hidden;
    .video-box {
      width: 800px;
      height: 440px;
      float: left;
      position: relative;
      .progress {
        position: absolute;
        position: absolute;
        left: 12px;
        bottom: 6px;
        width: 80%;
        height: 4px;
        background: #eeeeee;
        border-radius: 16px;
        p {
          position: absolute;
          left: 0px;
          top: 0;
          height: 100%;
          background: rgb(0, 193, 222);
          width: 100%;
          border-radius: 16px;
        }
      }
      video{
        object-fit: fill !important;
      }
      .marquee{
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        z-index: 8888;
        display: flex;
        opacity: .5;
      }
    }
  }
  .play-list {
    width: 400px;
    height: 440px;
    background-color: #2b334d;
    float: right;
  }
  .play-title {
    box-sizing: border-box;
    width: 340px;
    padding: 24px 24px 20px 0;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
    border-bottom: 2px solid #40475f;
    margin: 0 auto;
  }
  .course-list {
    height: 354px;
    margin: 0 auto;
    padding-top: 18px;
    padding-right: 10px;
    overflow-y: auto;
  }
  .course-name {
    font-size: 16px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 30px;
  }
  .course-info-list {
    padding: 24px 0;
  }
  .course-section {
    font-size: 14px;
    color: #d8d8d8;
    padding: 10px 30px;
    &:hover,
    &.active {
      background-color: #212943;
      font-weight: bold;
    }
    cursor: pointer;
    .state {
      float: right;
    }
  }
}
.evaluate-content {
  padding: 30px 0 88px;
  .evaluate {
    box-sizing: border-box;
    width: 1200px;
    padding: 28px 34px;
    margin: 0 auto;
    background-color: #fff;
  }
  .evaluate-text {
    box-sizing: border-box;
    width: 100%;
    height: 178px;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 6px;
    margin-bottom: 24px;
  }
  .evaluate-ope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 58px;
    .evaluate-ope {
      font-size: 16px;
      color: #ffffff;
    }
    .submit-btn {
      width: 114px;
      height: 40px;
      background: #254ed4;
      border-radius: 6px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
  .evaluate-h {
    font-size: 16px;
    font-weight: 900;
    color: #333;
  }
  .evaluate-list {
    .evaluate-info {
      padding: 24px 0;
      border-bottom: 1px solid #e5e5e5;
      overflow: hidden;
      .user-img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        float: left;
        margin-right: 20px;
      }
      .evaluate-content {
        width: 1000px;
        padding: 0;
        float: left;
      }
      .evaluate-title {
        padding-top: 10px;
        font-size: 16px;
        color: #666;
        margin-bottom: 10px;
        .date {
          font-size: 12px;
          color: #999999;
          float: right;
        }
      }
      .grade {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .grade-statistic {
          font-size: 14px;
          color: #666;
        }
        ::v-deep .stars {
          margin-bottom: 0;
          .star {
            width: 17px;
            height: 17px;
            margin-right: 8px;
          }
        }
      }
      .evaluate-text {
        font-size: 16px;
        color: #333;
        line-height: 18px;

        box-sizing: border-box;
        width: auto;
        height: auto;
        padding: 0;
        background: #fff;
        border-radius: 0;
        margin-bottom: 0;
      }
    }
  }
}
.stars {
  margin-bottom: 15px;
}
#J_prismPlayer{
  width: 100%;
  video{
    object-fit: fill;
  }
}
::v-deep .prism-player video{
  object-fit: fill;
}
</style>