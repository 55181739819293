function forceDownload(blob, filename) {
  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  a.click()
}

function download(url, filename) {
  url = url.replace('http://oss.images.kskstudy.com', 'https://oss.images.kskstudy.com')
  fetch(url)
    .then(response => response.blob())
    .then(blob => forceDownload(blob, filename))
    .catch(e => console.error('Something went wrong', e))
}

// function getExtension(url) {
//   // Split the URL by the dots and get the last segment
//   const parts = url.split('.')
//   return parts[parts.length - 1]
// }

export {
  download
}
